<template>
  <v-dialog
    v-model="isModalOpen"
    max-width="800px"
    persistent
  >
    <v-card
      class="pa-3"
      rounded="10"
      style="width: 100%"
    >
      <v-form ref="form">
        <div>
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            dense
            class="px-4"
          >
            <v-toolbar-title class="text-h6 pl-0">
              {{ t('products.product_adjustment') }} {{ computedDataParams.name }}
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="mt-5 justify-center d-flex flex-column">
            <v-row class="justify-center align-center">
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model.number="computedDataParams.prev_stock"
                  :label="t('products.system')"
                  dense
                  outlined
                  readonly
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model.number="computedDataParams.adj_stock"
                  :label="t('products.stock')"
                  dense
                  outlined
                  :rules="[validations.required, validations.integerValidator]"
                  @keypress="onlyNumbers"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="computedDiff"
                  label="+/-"
                  :class="{'plus': (computedDiff && computedDiff > 0), 'minus': (computedDiff && computedDiff < 0)}"
                  :style="{
                    color: `${(computedDiff && computedDiff > 0) ? '#56CA00' : '#FF4C51'} !important`,
                    caretColor: `${(computedDiff && computedDiff > 0) ? '#56CA00' : '#FF4C51'} !important`
                  }"
                  :color="(computedDiff && computedDiff > 0) ? '#56CA00' : '#FF4C51'"
                  dense
                  outlined
                  readonly
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="computedDataParams.reason"
                  :label="t('products.reason')"
                  :items="reasonOptions"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  clearable
                />
              </v-col>

              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-model="computedDataParams.observation"
                  :label="t('products.observation')"
                  dense
                  outlined
                  :prepend-inner-icon="icons.mdiCommentAlertOutline"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <v-card-actions class="d-flex flex-column justify-center py-3">
          <div class="d-flex justify-space-between">
            <v-btn
              class="text-xs me-2"
              color="secondary"
              dark
              width="55%"
              rounded
              @click="onSave"
            >
              {{ t('tooltip.save') }}
            </v-btn>
            <v-btn
              class="text-xs me-2"
              color="lightGray"
              dark
              width="30%"
              rounded
              outlined
              @click="$emit('update:is-modal-open', false)"
            >
              {{ t('tooltip.cancel') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCommentAlertOutline } from '@mdi/js'
import { ref, computed, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { required, integerValidator } from '@core/utils/validation'
import { onlyNumbers } from '@core/utils/functionsHelpers'

export default {
  name: 'ProductAdjustment',
  model: {
    prop: 'isModalOpen',
    event: 'update:is-modal-open',
  },
  props: {
    isModalOpen: {
      type: Boolean,
    },
    dataParams: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const form = ref(null)

    const computedIsModalOpen = computed(() => props.isModalOpen)
    const computedDataParams = computed(() => props.dataParams)
    const computedDiff = computed(() => {
      if (computedDataParams.value.prev_stock !== null && computedDataParams.value.adj_stock !== null) return computedDataParams.value.adj_stock - computedDataParams.value.prev_stock

      return null
    })

    const reasonOptions = computed(() => [
      { text: t('products.damaged'), value: 'D' },
      { text: t('products.expired'), value: 'E' },
      { text: t('products.lost'), value: 'L' },
    ])

    const onSave = () => {
      if (form.value.validate()) {
        const product = {
          product_id: computedDataParams.value.product_id,
          observation: computedDataParams.value.observation,
          reason: computedDataParams.value.reason,
          prev_stock: computedDataParams.value.prev_stock,
          adj_stock: computedDataParams.value.adj_stock,
        }
        emit('save', product)
      }
    }

    watch([computedIsModalOpen], () => {
      if (form.value) form.value.resetValidation()
    })

    return {
      // data
      form,

      // computed
      computedIsModalOpen,
      computedDataParams,
      computedDiff,
      reasonOptions,

      // methods
      onSave,
      onlyNumbers,

      // i18n
      t,

      validations: {
        required,
        integerValidator,
      },

      icons: {
        mdiCommentAlertOutline,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.minus :deep(input) {
  color: #FF4C51 !important;
  caret-color: #FF4C51 !important;

  input {
    color: #FF4C51 !important;
    caret-color: #FF4C51 !important;
  }
}

.plus :deep(input) {
  color: #56CA00 !important;
  caret-color: #56CA00 !important;

  input {
    color: #56CA00 !important;
    caret-color: #56CA00 !important;
  }
}
</style>
