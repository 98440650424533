<template>
  <div>
    <!-- :style="`box-shadow: 0px 3px 5px -1px ${computedProduct.color_hex}33, 0px 5px 8px 0px ${computedProduct.color_hex}24, 0px 1px 14px 0px ${computedProduct.color_hex}1f !important;`" -->
    <v-card
      elevation="5"
      :style="`box-shadow: 0px 3px 5px -1px #33333333, 0px 5px 8px 0px #33333333, 0px 1px 14px 0px #33333333 !important;`"
    >
      <v-tabs
        v-model="tab"
        show-arrows
        height="30"
        centered
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.id"
          color="secondary"
          :disabled="tab.disabled"
        >
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div>
            <div>
              <div>
                <div>
                  <v-card-title
                    class="text-h6 pa-3 justify-center font-weight-medium"
                  >
                    <span class="px-3">
                      {{ computedProduct.name }}
                    </span>
                    <v-menu
                      bottom
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          icon
                          small
                          absolute
                          right
                          class="dots-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon> {{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>

                      <v-list dense>
                        <v-list-item
                          v-for="item in menuOptions"
                          :key="item.id"
                          @click="item.action(computedProduct)"
                        >
                          <v-list-item-icon class="me-2">
                            <v-icon
                              size="20"
                              :color="item.color"
                            >
                              {{ item.icon }}
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-title>
                </div>
              </div>

              <!-- :class="`${computedProduct.color}--text`" -->
              <v-card-text
                class="py-1 d-flex justify-center align-center"
              >
                <v-img
                  :src="computedProduct.image ? computedProduct.image : '@/assets/images/pages/5.jpg'"
                  :class="$vuetify.breakpoint.mdAndUp ? 'rounded-lg':'rounded-0'"
                  aspect-ratio="1"
                  contain
                  width="auto"
                  height="90"
                ></v-img>
              </v-card-text>

              <v-card-actions class="dense justify-space-between px-6">
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on: tooltip, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="{ ...tooltip }"
                    >
                      {{ computedProduct.stock }} QTY
                    </span>
                  </template>
                  <span>{{ t('products.stock') }}</span>
                </v-tooltip>

                <v-tooltip
                  bottom
                >
                  <template #activator="{ on: tooltip, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="{ ...tooltip }"
                    >
                      DR {{ computedProduct.dr || 'N/A' }}
                    </span>
                  </template>
                  <span>{{ t('products.dr') }}</span>
                </v-tooltip>
              </v-card-actions>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div>P. Orders</div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiEyeOutline,
  mdiDotsVertical,
  mdiPlusMinusVariant,
  mdiPlus,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { deleteProduct } from '@api'
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'

export default {
  props: {
    product: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { router } = useRouter()

    const tab = ref(0)
    const tabs = ref([
      {
        title: 'Inventory',
        id: 0,
        disabled: false,
      },
      {
        title: 'P. Orders',
        id: 1,
        disabled: true,
      },
    ])

    const computedProduct = computed(() => props.product)

    const onEdit = item => {
      router.push({
        name: 'views-product-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 3,
        },
      })
    }

    const onShow = item => {
      router.push({
        name: 'views-product-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 2,
        },
      })
    }

    const onDelete = async item => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await deleteProduct(item.id)
          if (response.ok) {
            success(response.message)
            emit('refresh')
          } else {
            error(response.message.text)
          }
        }
      })
    }

    const menuOptions = ref([
      {
        id: 4,
        title: 'Show',
        icon: mdiEyeOutline,
        color: 'info',
        action: val => onShow(val),
      },
      {
        id: 2,
        title: 'Edit',
        icon: mdiPencilOutline,
        color: 'success',
        action: val => onEdit(val),
      },
      {
        id: 3,
        title: 'Delete',
        icon: mdiDeleteOutline,
        color: 'error',
        action: val => onDelete(val),
      },
      {
        id: 0,
        title: 'Adjustment',
        icon: mdiPlusMinusVariant,
        color: 'info',
        action: val => emit('adjustment', val),
      },
      {
        id: 1,
        title: 'Add QTY',
        icon: mdiPlus,
        color: 'info',
        action: val => emit('charge', val),
      },
    ])

    return {
      tab,
      tabs,
      menuOptions,

      computedProduct,

      onEdit,
      onShow,
      onDelete,

      t,

      icons: {
        mdiEyeOutline,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiPlusMinusVariant,
        mdiPlus,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.dots-right {
  right: 5px;
}

.v-tab {
  font-size: 0.75rem;
}

.v-tabs {
  border-bottom: none !important;
}

.v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
  box-shadow: none !important;
}
</style>
