<template>
  <div id="product-list">
    <v-card class="mb-8">
      <!-- <v-card-title>
        {{ t('Products') }}
      </v-card-title>

      <v-row
        class="px-2 ma-0 pb-2 text-center"
      >
        <v-col
          cols="12"
          md="3"
          sm="3"
        >
          <v-select
            v-model="productCategoryFilter"
            label="Categories"
            placeholder="Categories"
            :items="productCategories"
            item-text="name"
            item-value="id"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
          >
          </v-text-field>
        </v-col>

        <v-spacer />
        <v-col
          cols="12"
          md="2"
          sm="2"
        >
          <v-btn
            block
            color="secondary"
            @click="onCreate"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row> -->

      <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          md="3"
          sm="3"
        >
          <v-select
            v-model="productCategoryFilter"
            label="Categories"
            placeholder="Categories"
            :items="productCategories"
            item-text="name"
            item-value="id"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="hasPermission(50)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="onCreate"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-row v-if="products.length">
      <v-col
        v-for="product in products"
        :key="product.id"
        sm="3"
        cols="12"
      >
        <product-card
          :product="product"
          @adjustment="onAdjustment"
          @charge="onCharge"
          @refresh="refreshProducts"
        />
      </v-col>
    </v-row>

    <Inventory-adjustment
      v-model="isOpenAdjustment"
      :data-params="productAdjustment"
      @save="onSaveAdjustment"
    />

    <Inventory-charge
      v-model="isOpenCharge"
      :data-params="productCharge"
      @save="onSaveCharge"
    />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiPlus, mdiDeleteOutline, mdiPencilOutline, mdiEyeOutline } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { createInventaryAdjust } from '@api'

// sidebar
import { avatarText } from '@core/utils/filter'
import { error, success } from '@core/utils/toasted'
import useCryptoJs from '@/@core/utils/useCryptoJs'

import InventoryAdjustment from '@/views/inventory-adjustments/InventoryAdjustment.vue'
import InventoryCharge from '@/views/inventory-adjustments/InventoryCharge.vue'
import useProductList from './useProductList'
import ProductCard from './ProductCard.vue'

export default {
  components: {
    InventoryAdjustment,
    InventoryCharge,
    ProductCard,
  },
  setup() {
    const { t } = useUtils()
    const { router, route } = useRouter()
    const { userData } = useCryptoJs()

    const {
      products,
      productCategories,
      inventory,
      productCategoryFilter,
      configFacility,
      searchQuery,
      loading,
      options,
      filters,

      fetchProducts,
      fetchProductCategories,
      fetchInventoryFacility,
      hasPermission,
      setFilters,
      setOptions,
    } = useProductList()

    const isOpenAdjustment = ref(false)
    const productAdjustment = ref({
      product_id: null,
      observation: null,
      reason: null,
      prev_stock: null,
      adj_stock: null,
      name: null,
    })

    const isOpenCharge = ref(false)
    const productCharge = ref({
      product_id: null,
      observation: null,
      reason: null,
      prev_stock: null,
      adj_stock: null,
      name: null,
    })

    const bodyProduct = ref({
      facility_id: configFacility.value,
      user_id: userData.value.username,
      adjusted_products: [],
    })

    const onCreate = () => {
      router.push({
        name: 'views-product-form',
        params: {
          option: 1,
        },
      })
    }

    const refreshProducts = async () => {
      await fetchInventoryFacility()
      await fetchProducts()
    }

    const onAdjustment = value => {
      const finded = inventory.value.find(e => e.id === value.id)
      productAdjustment.value = {
        product_id: finded.id,
        observation: null,
        reason: null,
        prev_stock: finded.prev_stock,
        adj_stock: null,
        name: finded.name,
      }

      isOpenAdjustment.value = true
    }

    const onSaveAdjustment = async value => {
      const body = bodyProduct.value
      body.adjusted_products = [{
        product_id: value.product_id,
        observation: value.observation,
        reason: value.reason,
        prev_stock: value.prev_stock,
        adj_stock: value.adj_stock,
      }]

      const response = await createInventaryAdjust(body)
      isOpenAdjustment.value = false
      productAdjustment.value = {}
      if (response.ok) {
        success(response.message)
        await refreshProducts()
      } else {
        error(response.message.text)
      }
    }

    const onCharge = value => {
      const finded = inventory.value.find(e => e.id === value.id)
      productCharge.value = {
        product_id: finded.id,
        observation: null,
        reason: null,
        prev_stock: finded.prev_stock,
        adj_stock: null,
        name: finded.name,
      }

      isOpenCharge.value = true
    }

    const onSaveCharge = async value => {
      const body = bodyProduct.value
      body.adjusted_products = [{
        product_id: value.product_id,
        observation: value.observation,
        reason: value.reason,
        prev_stock: value.prev_stock,
        adj_stock: value.adj_stock,
      }]

      const response = await createInventaryAdjust(body)
      isOpenCharge.value = false
      productCharge.value = {}
      if (response.ok) {
        success(response.message)
        await refreshProducts()
      } else {
        error(response.message.text)
      }
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      await fetchProductCategories()
      await refreshProducts()
    })

    return {
      products,
      productCategories,
      inventory,
      productCategoryFilter,
      searchQuery,
      loading,
      options,
      filters,

      isOpenAdjustment,
      productAdjustment,
      isOpenCharge,
      productCharge,
      bodyProduct,

      onCreate,
      onAdjustment,
      onSaveAdjustment,
      onCharge,
      onSaveCharge,
      avatarText,

      fetchProducts,
      refreshProducts,
      hasPermission,
      setFilters,
      setOptions,

      // i18n
      t,

      // icons
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/sport.scss';
</style>
