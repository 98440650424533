var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{style:("box-shadow: 0px 3px 5px -1px #33333333, 0px 5px 8px 0px #33333333, 0px 1px 14px 0px #33333333 !important;"),attrs:{"elevation":"5"}},[_c('v-tabs',{attrs:{"show-arrows":"","height":"30","centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.id,attrs:{"color":"secondary","disabled":tab.disabled}},[_c('span',[_vm._v(_vm._s(tab.title))])])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('div',[_c('div',[_c('div',[_c('div',[_c('v-card-title',{staticClass:"text-h6 pa-3 justify-center font-weight-medium"},[_c('span',{staticClass:"px-3"},[_vm._v(" "+_vm._s(_vm.computedProduct.name)+" ")]),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"dots-right",attrs:{"dark":"","icon":"","small":"","absolute":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menuOptions),function(item){return _c('v-list-item',{key:item.id,on:{"click":function($event){return item.action(_vm.computedProduct)}}},[_c('v-list-item-icon',{staticClass:"me-2"},[_c('v-icon',{attrs:{"size":"20","color":item.color}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)]),_c('v-card-text',{staticClass:"py-1 d-flex justify-center align-center"},[_c('v-img',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'rounded-lg':'rounded-0',attrs:{"src":_vm.computedProduct.image ? _vm.computedProduct.image : '@/assets/images/pages/5.jpg',"aspect-ratio":"1","contain":"","width":"auto","height":"90"}})],1),_c('v-card-actions',{staticClass:"dense justify-space-between px-6"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.computedProduct.stock)+" QTY ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.t('products.stock')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),Object.assign({}, tooltip)),[_vm._v(" DR "+_vm._s(_vm.computedProduct.dr || 'N/A')+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.t('products.dr')))])])],1)],1)])]),_c('v-tab-item',[_c('div',[_vm._v("P. Orders")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }