import { ref, watch } from '@vue/composition-api'
import { getProductsCategoriesByFacility, getProductSummary, getEditInventory } from '@api'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useSelectOptions from '@core/utils/useSelectOptions'

export default function useProductList() {
  const { hasPermission } = usePermissions()
  const { configFacility } = useSelectOptions()

  const products = ref([])
  const productCategories = ref([])
  const inventory = ref([])

  const productCategoryFilter = ref(0)
  const searchQuery = ref('')

  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const filters = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)

  // fetch data
  const fetchProducts = async () => {
    const response = await getProductSummary(configFacility.value, productCategoryFilter.value || 0, searchQuery.value || 'null')
    products.value = response.data
    loading.value = false
  }

  const fetchProductCategories = async () => {
    const response = await getProductsCategoriesByFacility(configFacility.value || 0)
    productCategories.value = response.data
  }
  const fetchInventoryFacility = async () => {
    const response = await getEditInventory(configFacility.value || 0)
    inventory.value = response.data
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
    }
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchProducts()
    }, 1000)
  }

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await fetchProducts()
    }
  })

  watch([productCategoryFilter], async () => {
    await fetchProducts()
  })

  watch([configFacility], async () => {
    searchQuery.value = ''
    productCategoryFilter.value = null
    await fetchProductCategories()
    await fetchProducts()
  })

  return {
    products,
    productCategories,
    inventory,
    productCategoryFilter,
    searchQuery,
    loading,
    options,
    filters,
    configFacility,

    fetchProducts,
    fetchProductCategories,
    fetchInventoryFacility,
    hasPermission,
    setFilters,
    setOptions,
  }
}
